import React, { useEffect } from 'react'
import moment from 'moment'
import connect from './connect'
import SharingLinkModal from './sharing_link_modal'

function SharingLinks({
  newLinkButtonTitle,
  toggleDialog,
  saveSharingLink,
  deleteSharingLink,
  dialogOpened,
  isFetching,
  windowTitle,
  resourceUrlTitle,
  createSharingLinkTitle,
  updateSharingLinkTitle,
  expiresAtFieldTitle,
  expiresAtFieldHint,
  usernameFieldTitle,
  newSharingLink,
  currentLink,
  sharingLinks,
  loadSharingLinks,
  links,
  successMessage,
  errorMessage,
}) {
  useEffect(() => {
    loadSharingLinks(sharingLinks)
  }, [sharingLinks])
  return (
    <div className="sharing-links">
      <div className="sharing-links-list mb-2">
        {links.map((link) => {
          return (
            <div key={link.id}>
              <a href="javascript:void(0)" onClick={() => toggleDialog(true, link)}>
                {`${link.user.name} / ${moment(link.expiresAt).format('YYYY-MM-DD')}`}
              </a>
            </div>
          )
        })}
      </div>
      <button className="btn btn-secondary w-100" type="button" onClick={() => toggleDialog(true, newSharingLink)}>
        {newLinkButtonTitle}
      </button>
      {currentLink && (
        <SharingLinkModal
          dialogOpened={dialogOpened}
          windowTitle={windowTitle}
          resourceUrlTitle={resourceUrlTitle}
          toggleDialog={toggleDialog}
          currentLink={currentLink}
          isFetching={isFetching}
          saveSharingLink={saveSharingLink}
          deleteSharingLink={deleteSharingLink}
          successMessage={successMessage}
          errorMessage={errorMessage}
          expiresAtFieldTitle={expiresAtFieldTitle}
          expiresAtFieldHint={expiresAtFieldHint}
          usernameFieldTitle={usernameFieldTitle}
          createSharingLinkTitle={createSharingLinkTitle}
          updateSharingLinkTitle={updateSharingLinkTitle}
        />
      )}
    </div>
  )
}

export default connect(SharingLinks)
