import React from 'react'

export default ({ editableTitle, title, name, placeholder, onChange, downloadAllowed, file }) => {
  if (editableTitle) {
    return (
      <input
        type="text"
        name="title"
        className="form-control"
        placeholder={placeholder}
        defaultValue={title}
        onChange={onChange}
      />
    )
  }

  if (downloadAllowed && file) {
    return (
      <a className="download-lnk" href={file.downloadUrl || file.href || file.src} target="_blank" rel="noopener noreferrer">
        {name}
      </a>
    )
  }

  return name
}
