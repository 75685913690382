import { React, store, styles } from './default_imports'

export default class AddControl extends React.Component {

  onClick (e) {
    e.preventDefault()
    store.add({id: null, title: '', description: '', url: ''})
  }

  render () {
    return (
      <div className={styles['add-control']}>
        <span>{I18n.t('link_input.add_control.text')}</span>
        <a href="#" onClick={this.onClick}>{I18n.t('link_input.add_control.link_text')}</a>
      </div>
    )
  }
}
