import { Component } from 'react'
import PropTypes from 'prop-types'
import styles from '../../graph_styles.sass'
import classNames from 'classnames'
import { Doughnut, Bar } from 'react-chartjs-2'
import config from '../../../config'

const CHART_OPTIONS = {
  responsive: true,
  legend: {
    position: 'bottom',
  }
}

export default class ProjectGroup extends Component {
  static propTypes = {
    projectGroup: PropTypes.object.isRequired,
  }

  onElementsClick = ([element, _]) => {
    if (element) {
      const status = element._chart.options.meta.statusKeys[element._index]
      this.props.getProjects({ group_id: this.props.projectGroup.id, status })
      this.props.assignGroup(this.props.projectGroup)
    }
  }

  renderChart () {
    const projectGroup = this.props.projectGroup

    const chartOptionsWithMeta = Object.assign({}, CHART_OPTIONS, { meta: { statusKeys: config.statusKeys } })
    return (
      <Doughnut data={this.buildChartData()} options={chartOptionsWithMeta} height={200} onElementsClick={this.onElementsClick} />
    )
  }

  buildChartData () {
    return {
      labels: this.buildLabels(),
      datasets: [{
        data: this.buildData(),
        backgroundColor: this.buildColors(),
        hoverBackgroundColor: this.buildColors(),
      }]
    }
  }

  buildLabels = () => config.statuses.map(status => status.title())
  buildColors = () => config.statuses.map(status => status.color)
  buildData = () => config.statuses.map(status => this.props.projectGroup[status.name])

  render () {
    const { projectGroup} = this.props
    return (
      <div className="col-4">
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <a href={projectGroup.link}>
              {projectGroup.name}
            </a>
          </div>
          <div className={styles.bodyContainer}>
            {this.renderChart()}
            <div className={styles.commentContainer}>
              <span>{projectGroup.comments_count}</span>
              <i className="fa fa-comments" />
            </div>
            <div className={styles.companyLogoContainer}>
              <img src={projectGroup.company_logo} alt={projectGroup.company_name} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
