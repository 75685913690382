import { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './styles.sass'
import Comment from './comment'

const I18n = window.I18n

const Translations = {
  attachments: I18n.t('activerecord.models.attachments/base.other').toLowerCase(),
  goToComment: I18n.t('application.actions.goto', {
    item: I18n.t('activerecord.models.comment.one').toLowerCase()
  })
}

export default class CommentList extends Component {
  static propTypes = {
    comments: PropTypes.object.isRequired,
  }

  state = { comment: null }

  closeModal = () => {
    this.assignComment(null)
  }

  assignComment = (comment = null) => {
    this.setState({ comment })
  }

  render () {
    const { comments, ModalWindow } = this.props
    const { id, body, attachment_count, meta, company_name, project_title, link } = this.state.comment || {}

    return (
      <div className={styles.container}>
        <ModalWindow header={`${company_name}: ${project_title}`}  onClose={this.closeModal} isOpen={!!id}>
          <div className="card">
            <div className="card-body">
              <div>
                {body}
                {attachment_count && `(${attachment_count} ${Translations.attachments})`}
              </div>
              <small className="text-muted float-right">
                {meta}&nbsp;<a href={link} target="_blank">{Translations.goToComment}</a>
              </small>
            </div>
          </div>
        </ModalWindow>
        <table>
          <tbody>
            { comments.map(comment => (
                <Comment comment={comment} key={comment.get('id')} assignComment={this.assignComment} />
              ))
            }
          </tbody>
        </table>
      </div>
    )
  }
}
