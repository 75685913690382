import { Component } from 'react'
import PreviewImageItem from 'components/shared/preview_image_item'
import ReactHoverObserver from 'react-hover-observer'
import ControlMenu from './control_menu'

export default class ImageList extends Component {

  static defaultProps = {
    images: [],
    onFileRemoved: function () {},
    controlMenu: true
  }

  removeOnClick = (e) => {
    e.preventDefault()
  }

  render () {
    const { removeFile, controlMenu } = this.props
    const images = this.props.images.filter(img => !img._destroy)

    return (
      <div className="images-with-preview">
        { images.map((image, index) =>
          <div className="item" key={`react-hover-${index}`}>
              <PreviewImageItem preview {...image} src={image.href || image.src} style={{ top: `${index * 5}px`, left: `${index * 5}px` }}>
                <a href={image.src} onClick={this.removeOnClick} id={`image-${image.id}`}>{I18n.t('campaigns.others.attached_file_name')}</a>
              </PreviewImageItem>
            { controlMenu && <ControlMenu image={image} removeImage={removeFile} /> }
          </div>
          )
        }
      </div>
    )
  }
}
