import styles from './item_selector_styles'

class ItemInterface {

  constructor () {
    this.options = { styles }
  }

  extendSelectOptions = (items) => items

  optionRenderer () {
    return this.renderOption || null
  }
}

export default ItemInterface
