import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './styles.sass'
import Status from './status'
import config from '../../config'

export default class StatusSummary extends Component {
  static propTypes = {
    projectGroups: PropTypes.array.isRequired,
    mspWorkflows: PropTypes.array.isRequired
  }

  state = { status: null }

  assignStatus = (status = null) => {
    this.setState(prevState => Object.assign({}, prevState, { status }))
    if (status) {
      const { projectGroups, mspWorkflows, getProjects } = this.props
      getProjects({
        project_group_ids: projectGroups.map(g => g.id),
        msp_workflow_ids: mspWorkflows.map(mspw => mspw.id),
        status: status.scope
      })
    }
  }

  closeModal = () => this.setState({ status: null })

  render () {
    const { projectGroups, mspWorkflows, projects, ModalWithProjects } = this.props
    const items = projectGroups.concat(mspWorkflows)

    return (
      <div>
        <ModalWithProjects name={this.state.status?.title()} isOpen={!!this.state.status} closeModal={this.closeModal} projects={projects} />
        <div className={styles.container}>
          { config.statuses.map((status, i) => (
              <Status key={i} status={status} items={items} assignStatus={this.assignStatus} />
            ))
          }
        </div>
      </div>
    )
  }
}
