import { React, PropTypes, store, styles } from './default_imports'
import List from './list'
import AddControl from './add_control'


const propTypes = {
  links: PropTypes.array,
  placeholders: PropTypes.object
}

const defaultProps = {
  links: [],
  placeholders: { url: 'Url', title: 'Title', description: 'Description' }
}

class AttachableLinks extends React.Component {

  constructor (props) {
    super(props)
    store.init(props.links, props.placeholders, props.attrsBaseName)
    this.listener = store.addListener('change', () => this.forceUpdate())
  }

  componentWillUnmount () {
    this.listener.remove()
  }

  render () {
    return (
      <div className={styles['links']}>
        <List links={store.links} />
        <AddControl />
      </div>
    )
  }
}

AttachableLinks.propTypes = propTypes
AttachableLinks.defaultProps = defaultProps

export default AttachableLinks
