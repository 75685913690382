import { fromJS, Map } from 'immutable'
import { types } from '../../actions/automation'

export default function reducer(state = Map({}), action: any = {}) {
  switch (action.type) {
    case types.SELECT_ACTION:
      return state.set('selectedAction', fromJS(action.actionName))
    default:
      return state
  }
}
