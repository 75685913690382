import React from 'react'
import Select from 'components/shared/base_react_select'
import connect from './connect'
import TableWithExtraAmountInputs from './table_with_extra_amount_inputs'
import styles from './styles'
import classNames from 'classnames'

class CreatePostorderForm extends React.Component {

  onChange = (opt) => {
    this.props.getItems(this.props.campaignId, opt.value)
  }

  onClick = () => {
    this.props.createPostorderWithItems(this.props.campaignId, this.props.locationId, this.props.items)
  }

  render () {
    const options = this.props.locations.toJS()
    return (
      <div className="post-order-form-container">
        <div className={classNames(['row', styles['one-location-selector-row']])}>
          <div className="col-12">
            <Select
              value={options.find(o => o.value === this.props.locationId)}
              options={options}
              onChange={this.onChange}
            />
          </div>
        </div>
        <div className={classNames(['row', styles['table-with-exra-amount-row']])}>
          <div className="col-12">
            <TableWithExtraAmountInputs items={this.props.items} updateItem={this.props.updateItem} />
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-2">
            <button type="button" className="send-postorder btn btn-primary" onClick={this.onClick}>
              {window.I18n.t('campaign_postorders.send')}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(CreatePostorderForm)
