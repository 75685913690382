import { Component } from 'react'
import moment from 'moment-timezone'
import classNames from 'classnames'
import DatePicker from 'react-datepicker'
import changeHandlerFor from './change_date_time_handlers'
import styles from './styles.sass'

const DATE_FORMAT = "YYYY-MM-DD"
const TIME_FORMAT = "HH:mm"

class DateTimeInput extends Component {
  constructor (props) {
    super(props)
    this.state = { value: props.value ? this.buildDateOrDateTime(props.value) : null }

    if (this.props.changeHandler) {
      const HandlerClass = changeHandlerFor(this.props.changeHandler)
      const handlerOptions = Object.assign(this.props.changeHandlerOptions, { DATE_FORMAT, TIME_FORMAT })
      this.changeHandler = new HandlerClass(handlerOptions)
    }
  }

  onChange = value => {
    if (this.changeHandler) {
      this.changeHandler.updatedTo(value).then((newValue) => {
        this.setState({ value: newValue })
      })
    } else this.setState({ value })
  }

  onClickIcon = e => this.picker.setOpen(true)

  buildDate = value => moment(value)

  buildDateTime = value => moment(value).tz(this.props.timeZone)

  buildDateOrDateTime = (value) => this.props.showTimeSelect ? this.buildDateTime(value) : this.buildDate(value)

  dateFormat = () => {
    let format = DATE_FORMAT
    if (this.props.showTimeSelect) {
      format = format + ' ' + TIME_FORMAT
    }
    return format
  }

  prepareProps () {
    return {
      ref: e => this.picker = e,
      className: "form-control",
      timeFormat: 'HH:mm',
      openToDate: this.state.value || this.buildDateOrDateTime(new Date()),
      timeIntervals: 15,
      isClearable: this.props.clearable,
      dateFormat: this.dateFormat(),
      selected: this.state.value,
      onChange: this.onChange,
      ..._.omit(this.props, 'value', 'time_zone'),
    }
  }
  renderAppendIcon () {
    return (
      <div className={classNames('input-group-append', styles.inputGroupAppend)} onClick={this.onClickIcon}>
          <span className={classNames('input-group-text', styles.inputGroupText)}>
            <i className="fa fa-calendar-o" />
          </span>
      </div>
    )
  }

  render () {
    return (
      <div className={`input-group ${styles.container}`}>
        <DatePicker {...this.prepareProps()} />
        {this.renderAppendIcon()}
      </div>
    )
  }
}

export default DateTimeInput
