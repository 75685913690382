import React from 'react'
import classNames from 'classnames'
import Confirmation from 'components/shared/modal_windows/simple_confirmation'
import AttachmentCategory from 'components/shared/attachment_category'
import CheckControl from './check_control'
import SortControl from './sort_control'
import Thumb from './thumb'
import NameView from './name_view'
import DescriptionView from './description_view'
import DownloadImageButton from './download_image_button'

const RemoveControl = (props) => (
  <a className="delete-button" {...props}>
    <i className="fa fa-times-circle fa-lg" aria-hidden="true" />
  </a>
)

const isImageFile = (file) => (file.src || '').match(/\.(png|jpe?g|gif|svg)(\?.*)?$/i)
const isVideoFile = (file) => (file.src || '').match(/\.(mp4|webm|mkv|flv|avi|mov|mpeg)(\?.*)?$/i)

const FileItem = ({ file, options, modelData, onDelete, onChange, isReqStateInProgress }) => {
  const { id, title, name, downloadUrl, href, categoryId, description } = file
  const {
    selectable,
    sortable,
    downloadAllowed,
    editableTitle,
    editableDescription,
    titlePlaceholder,
    descriptionPlaceholder,
    attachmentCategoryUrl,
  } = options

  const isImage = isImageFile(file)
  const isVideo = isVideoFile(file)
  const fileTypeClass = isImage ? 'image-type' : 'attachment'

  const handleDelete = () => onDelete(id)
  const handleChanges = (e) => onChange(id, e)
  const handleChangeCategory = (option) =>
    onChange(id, {
      target: {
        value: option.value,
        name: 'category_id',
      },
    })

  return (
    <div className="file-info card" id={`uploader_${id}`}>
      <div className="header-controls">
        {selectable && !isReqStateInProgress && <CheckControl onChange={handleChanges} />}
      </div>
      <div className="card-body">
        {sortable && !isReqStateInProgress && <SortControl />}
        <div className={classNames(['file-info-thumb', fileTypeClass])}>
          <Thumb
            file={file}
            downloadAllowed={downloadAllowed}
            modelData={modelData}
            isImage={isImage}
            isVideo={isVideo}
          />
          <div className="file-info-name">
            {!isImage && (
              <NameView
                editableTitle={editableTitle}
                title={title}
                name={name}
                placeholder={titlePlaceholder}
                onChange={handleChanges}
                downloadAllowed={downloadAllowed}
                file={file}
              />
            )}
          </div>
          {isImage && downloadAllowed && <DownloadImageButton url={downloadUrl || href} />}
          {!isReqStateInProgress && (
            <Confirmation show={false} confirmationHandler={handleDelete} actionComponent={RemoveControl} />
          )}
        </div>
        {attachmentCategoryUrl && (
          <AttachmentCategory url={attachmentCategoryUrl} value={categoryId} onChange={handleChangeCategory} />
        )}
        <div className="file-info-description">
          <DescriptionView
            editableDescription={editableDescription}
            description={description}
            placeholder={descriptionPlaceholder}
            onChange={handleChanges}
          />
        </div>
      </div>
    </div>
  )
}

export default FileItem
