import { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './styles.sass'
import Link from './link'
import * as selectors from 'reducers/project/selectors'

export default class List extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }

  onOpenModal = () => this.props.openImagesModal(this.props.id)

  renderPhotos () {
    const links = selectors.getLinksByElement(this.props.links, this.props.id)

    if (!links.size) {
      return (
        <div className={styles.imageList}>
          <span className="badge badge-danger">{I18n.t('element_photos.list.no')}</span>
        </div>
      )
    }

    return (
      <div className={styles.imageList}>
        {links.map(link => {
          const photo = selectors.getPhotoByLink(this.props.photos, link)
          if (!photo) return null

          return (
            <Link link={link} key={link.get('attachment_id')} photo={photo} />
          )
        })}
      </div>
    )
  }
  render () {
    const { projectStatus, projectLoaded, type } = this.props
    if (!projectLoaded) { return null }
    if (projectStatus === 'approved') { return null }
    if (type !== 'Elements::LocationElement') { return null }

    return (
      <section>
        <div className={styles.imageContainer}>
          <div className={styles.title}>{I18n.t('element_photos.list.attached_images')}</div>
          {this.renderPhotos()}
        </div>
        <button className="btn btn-light" onClick={this.onOpenModal}>{I18n.t('element_photos.list.attach_image')}</button>
      </section>
    )
  }
}
