import { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './styles.sass'
import classNames from 'classnames'

export default class Photo extends Component {
  static propTypes = {
    photo: PropTypes.object,
  }

  onClick = (e) => {
    const { onClick, selected, photo } = this.props

    onClick(photo.get('id'), !selected)
  }

  render () {
    const { photo, selected } = this.props
    return (
      <div
        onClick={this.onClick}
        className={styles.container}
        style={{ backgroundImage: `url(${photo.get('large_url')})` }}
      >
        <div className={classNames(styles.indicator, 'fa', { 'fa-check': selected, [styles.selected]: selected })} />
      </div>
    )
  }
}
