import * as React from 'react'

const { I18n } = window as any

interface DecorationTypeDetailsProps {
  disabled: boolean
  value: {
    decoration_type: string
  } | null
}

export default function DecorationTypeDetails(props: DecorationTypeDetailsProps) {
  const { disabled, value } = props

  const [decorationType, setDecorationType] = React.useState(value?.decoration_type ? value.decoration_type : 'small_sign')

  return (
    <div>
      <div>
        <label className="bold-headline">{I18n.t('order_requests.form.decoration_details')}*</label>
        <div className="input form-check">
          <label className="form-check-label">
            <input
              disabled={disabled}
              name="order_request[decoration_type]"
              className="form-check-input"
              type="radio"
              value="small_sign"
              checked={decorationType === 'small_sign'}
              onChange={() => setDecorationType('small_sign')}
            />
            {I18n.t('order_requests.form.decoration_options.first')}
          </label>
        </div>
        <div className="input form-check">
          <label className="form-check-label">
            <input
              disabled={disabled}
              name="order_request[decoration_type]"
              className="form-check-input"
              type="radio"
              value="large_sign"
              checked={decorationType === 'large_sign'}
              onChange={() => setDecorationType('large_sign')}
            />
            {I18n.t('order_requests.form.decoration_options.second')}
          </label>
        </div>
        <div className="input form-check">
          <label className="form-check-label">
            <input
              disabled={disabled}
              name="order_request[decoration_type]"
              className="form-check-input"
              type="radio"
              value="no_sign"
              checked={decorationType === 'no_sign'}
              onChange={() => setDecorationType('no_sign')}
            />
            {I18n.t('order_requests.form.decoration_options.third')}
          </label>
        </div>
      </div>
    </div>
  )
}
