import styles from './styles'

const I18n = window.I18n

const inlineErrorClass = styles['inline-error']
const inlineErrorSelector = `.${inlineErrorClass}`
const valueCellSelector = '.amount-cell'

export const buildMessageFor = (min, max) => {
  return I18n.t('activerecord.errors.models.elements/base_element.attributes.amount.exceed_min_and_max_limit')
    .replace('%{min}', min).replace('%{max}', max)
}

export const valueBasedOnMinMaxIsValid = (val, min, max) => {
  if (val && min && val < min) { return false }
  if (val && max && val > max) { return false }
  return true
}

export const inlineError = (msg) => {
  return `<div class='${inlineErrorClass}'>${msg}</div>`
}

export const showErrorMsg = ($inp, min, max) => {
  const $parent = $inp.parent(valueCellSelector)
  if ($(inlineErrorSelector, $parent).size() == 0) {
    const msg = buildMessageFor(min, max)
    $(inlineError(msg)).insertAfter($inp)
  }
}

export const hideErrorMsg = ($inp) => {
  const $parent = $inp.parent(valueCellSelector)
  $(inlineErrorSelector, $parent).remove()
}

export default {
  buildMessageFor, valueBasedOnMinMaxIsValid, inlineErrorClass
}
