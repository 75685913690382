import ThumbImage from './thumb_image'
import ThumbVideo from './thumb_video'

export default ({ file, downloadAllowed, modelData, isImage, isVideo }) => {
  if (file._clientUploading || file._serverUploading) {
    return (
      <div className="loading" />
    )
  }

  if (isImage) { return <ThumbImage file={file} modeData={modelData} /> }
  if (isVideo) { return <ThumbVideo file={file} modeData={modelData} /> }

  const href = file.downloadUrl || file.href || file.src

  if (downloadAllowed && href) {
    return (
      <a className="download-lnk" href={href} target="_blank">
        <span className="fa fa-file-text-o" />
      </a>
    )
  }

  return (
    <span className="fa fa-file-text-o" />
  )
}
