import { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './styles.sass'
import StatusChange from './status_change'

const I18n = window.I18n

export default class StatusChangeList extends Component {
  static propTypes = {
    statusChanges: PropTypes.object.isRequired,
  }

  state = { change: null }

  closeModal = () => {
    this.assignChange()
  }

  assignChange = (change = null) => {
    this.setState({ change })
  }

  render () {
    const { ModalWindow, statusChanges } = this.props
    const { id, company_name, new_status, old_status, project_title, meta } = this.state.change || {}

    return (
      <div className={styles.container}>
        <ModalWindow header={`${company_name}: ${project_title}`} onClose={this.closeModal} isOpen={!!id}>
          <table className="table table-sm">
            <thead>
              <tr>
                <th>{I18n.t('application.titles.from')}</th>
                <th>{I18n.t('application.titles.to')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{old_status}</td>
                <td>{new_status}</td>
              </tr>
            </tbody>
          </table>
          <small className="text-muted float-right">{meta}</small>
        </ModalWindow>
        <table>
          <tbody>
            { statusChanges.map(status => (
                <StatusChange statusChange={status} key={status.get('id')} assignChange={this.assignChange} />
              ))
            }
          </tbody>
        </table>
      </div>
    )
  }
}
