import styles from './item_selector_styles'
import ItemInterface from './item_interface'

class ElementItemInterface extends ItemInterface {

  extractBaseItemAttrs (item) {
    return { value: item.value, name: item.name }
  }

  extendSelectOptions = (items) => {
    return items.map((item) => this.extendOptionItem(item))
  }

  extendOptionItem (item) {
    return Object.assign(this.extractBaseItemAttrs(item), {
      label: `${item.value} ${item.label}`,
      otherLabel: item.label ? item.label.split(' ').join('  ') : '',
    })
  }

  renderOption = (option) => {
    return (
      <div>
        <span className={styles['item-id']}>{option.value}</span>
        <span className={styles['item-name']}>{option.name}</span>
        &nbsp;&nbsp;
        <span className={styles['other-item-parts']}>{option.otherLabel}</span>
      </div>
    )
  }
}

export default ElementItemInterface
