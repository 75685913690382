import { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './styles.sass'
import classNames from 'classnames'

export default class StatusChange extends Component {
  static propTypes = {
    statusChange: PropTypes.object.isRequired,
  }

  onClick = () => {
    this.props.assignChange(this.props.statusChange)
  }

  render () {
    const { statusChange } = this.props
    return (
      <tr className={styles.container}>
        <td className={styles.icon}>
          <i className="fa fa-arrow-circle-right" onClick={this.onClick} />
        </td>
        <td className={classNames(styles.body, 'small')}>
          {`${statusChange.get('old_status')} --> ${statusChange.get('new_status')}`}
        </td>
        <td className={classNames(styles.projectInfo, 'small')}>
          {statusChange.get('location_name')}
        </td>
        <td className={classNames(styles.projectInfo, 'small')}>
          {statusChange.get('company_name')}<br />
          {statusChange.get('project_id')}
        </td>
      </tr>
    )
  }
}
