import { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './styles.sass'
import classNames from 'classnames'

export default class Comment extends Component {
  static propTypes = {
    comment: PropTypes.object.isRequired,
  }

  onClick = () => {
    this.props.assignComment(this.props.comment.toJS())
  }  

  render () {
    const comment = this.props.comment
    return (
      <tr className={styles.container}>
        <td className={styles.icon}>
          <i className="fa fa-comments" onClick={this.onClick} />
        </td>
        <td className={classNames(styles.body, 'small')}>
          <a href={comment.get('link')}>
            {comment.get('body')}
          </a>
        </td>
        <td className={classNames(styles.projectInfo, 'small')}>
          {comment.get('company_name')}<br />
          {comment.get('project_id')}
        </td>
      </tr>
    )
  }
}
