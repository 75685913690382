import { fromJS } from 'immutable'

const defaultState = fromJS({
  automation: {
    ruleEdit: {
      selectedAction: '',
    }
  },
  project: {
    id: null,
    status: null,
    photos: [],
    links: [],
    formData: {
    },
    transient: {
      loaded: false,
      reservedLinks: null,
    },
  },
  projects: {
    mspData: {
      current: { projectId: null, stepId: null, mspProjectId: null },
      projects: [],
      workflows: [],
      steps: [],
    },
    form: {
      availableVendors: [],
      availableInstallers: [],
      availableLocations: [],
      availableProjectTags: [],
      availableFinanceTags: [],
      location: null,
      vendors: [],
      installers: [],
      projectTags: [],
      financeTags: [],
      companies: [],
      groups: [],
      fieldsHtml: '',
      createServiceCompany: { error: null, success: null },
      createInstaller: { error: null, success: null },
      element_groups: [],
      elements: [],
      selected_elements: [],
    },
    duplicate: { locations: [] },
    send_links: { projects: [] },
    bulk_assign_installers: {
      errors: null,
      ready_to_reload_page: null,
    },
  },
  element: {
    group: {
      enabledColumns: {
        selectedColumns: [],
      }
    },
    transient: {
      activeElement: null,
    },
  },
  templateFields: {
    masterList: [],
    fieldsOptions: {},
    fields: {},
    translations: {}
  },
  elementsOrder: {
    currentElementGroup: null,
    currentDepartment: null,
    currentElementSubGroup: null,
    currentTemplate: null,
    currentTemplateData: null,
    currentReplaceElementId: null,
    currentElement: null,
    searchTerm: null,
    templates: [],
    templatesLoading: false,
    installedElements: [],
    installedElementsLoading: false,
    elements: {},
    removed: [],
    filtered: {
      elementGroups: [],
      departments: [],
      elementSubGroups: [],
    },
    dictionaries: {
      elementGroups: [],
      departments: [],
      elementSubGroups: [],
      locationGroups: [],
      locationSubGroups: [],
      locationDepartments: [],
    },
  },
  rentList: {
    elementGroups: {},
    groupedAvailableElements: {},
    rentElementRequestInProgress: false,
    rentedItems: {},
    errors: [],
  },
  report: {
    id: null,
    columns: [],
    conditions: {},
  },
  reports: {
    projectsReport: {
      availableFilters: [],
      selectedFilters: {},
      savedFilters: [],
    },
  },
  survey: {
    adminElementEdit: {
      deletedIds: [],
      fields: [],
      siblings: [],
      templates: {},
      templateId: '',
      templateFields: {},
      optionFields: {},
    },
  },
  sharingLinks: {
    dialogOpened: false,
    currentLink: null,
    successMessage: null,
    errorMessage: null,
    links: [],
  },
  common_element_tag: {
    id: null,
    columns: [],
    conditions_for_elements: {},
    conditions_for_locations: {},
  },
  dictionaries: {
    conditions: {
      models: [],
      operations: [],
      related_dates: [],
    },
  },
  campaigns: {
    elementFormData: {},
    locations: [], tags: [], regions: [],
    elements: [],
    amounts: {},
    id: null,
    step: null,
    isReady: false,
    activeItem: null,
    hiddenFields: []
  },
  campaign_postorders: {
    campaignId: '',
    locationId: '',
    locations: [],
    items: []
  },
  overview: {
    projectGroups: [],
    mspWorkflows: [],
    comments: [],
    statusChanges: [],
    projects: []
  },
  uploaderOptions: {
    url: '',
    backend: '',
    params: { }
  },
  uploader: {

  },
  requestsStatus: {

  },
  formBuilder: {
    past: [],
    present: { },
    future: []
  },
  manualPages: {
    treeData: [],
    page: {},
    filter: ''
  },
  multiStep: {
    workflowOverviewBoard: {

    }
  },
  admin: {
    imports: {
      template: '',
      settings: { template: {}, searchAttrs: [] }
    }
  }
})

export default defaultState
