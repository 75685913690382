import React from 'react'
import className from 'classnames'
import styles from './styles'

const Spinner = (props) => {
  const size = props.size || 2
  return (
    <div className={className([props.className, styles[props.viewType]])}>
      <i className={`fa fa-spinner fa-spin fa-${size}x`} />
    </div>
  )
}

Spinner.defaultProps = {
  className: null
}

export const VCSpinner = (props) => {
  if (!props.visible) { return null }

  return (<Spinner {...props} />)
}

export default Spinner
