import * as React from 'react'
import { Creatable as CreatableSelect, default as BaseSelect } from '../../shared/base_react_select'

const { I18n } = window as any

type Option = {
  value: string
  label: string
}

interface CarBrandSelectorProps {
  collection: Option[]
  value: Option[] | null
  disabled: boolean
  canCreate?: boolean
}

export default function CarBrandSelector(props: CarBrandSelectorProps) {
  const { collection, value, disabled, canCreate} = props
  const [options, setOptions] = React.useState<Option[]>(collection)
  const [selectedValue, setSelectedValue] = React.useState<Option | null>(value?.length ? value[0] : null)

  const handleChange = (opt: Option) => {
    setSelectedValue(opt)
  }

  const handleCreate = (inputValue: string) => {
    const newOption = { value: inputValue, label: inputValue }
    setOptions([...options, newOption])
    setSelectedValue(newOption)
  }

  const SelectComponent = canCreate !== false ? CreatableSelect : BaseSelect

  return (
    <>
      {canCreate == false && <label className="label bold-headline">{I18n.t('order_requests.form.select_combination')}*</label>}
      <SelectComponent
        required
        value={selectedValue}
        options={options}
        isDisabled={disabled}
        onChange={(opt) => handleChange(opt)}
        onCreateOption={canCreate ? handleCreate : undefined}
        isClearable
        placeholder={I18n.t('application.actions.select_or_type')}
      />
      <input type="hidden" name="order_request[car_brand]" value={selectedValue ? selectedValue.value : ''} />
    </>
  )
}
