import { React, PropTypes, store, styles } from './default_imports'

export default class DeleteControl extends React.Component {
  constructor (props) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }

  onClick () {
    store.remove(this.props.index, this.props.item.id)
  }

  render () {
    return (
      <div className={styles['delete-control']}>
        <i className="fa fa-times-circle fa-lg" aria-hidden="true" onClick={this.onClick}></i>
      </div>
    )
  }
}
