import { React, PropTypes, store, styles } from './default_imports'
import Item from './item'

export default function List (props) {
  return (
    <div className={styles['list']}>
      {props.links.map((item, index) => <Item data={item} index={index} key={item.key} />)}
    </div>
  )
}
